/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import styled from "styled-components";
import logo from '../img/aboutlogo.png'
import Container from './Container.jsx';



// const lis = document.querySelectorAll("li");
// const ul = document.querySelector(".shit");

export default class Navbar extends Component {
  constructor(props){
    super(props);
    this.state = {
      clicked: false,
      timeout: 0,
    }
    
    this.handleClick = this.handleClick.bind(this);
    
  }

  handleClick(x){
    if(!x){
      
      this.c = setTimeout(() => {this.setState(state => ({
        clicked: x,
      }) )}, 100)
    }

    else{
      this.setState(state => ({
        clicked: x,
      }))

    }
  }
  

  
  render() {
    let list;



    if(this.state.clicked){
      list =
      <ul className='popUp'
      onMouseEnter={() => clearTimeout(this.c)}
      onMouseLeave = {() => this.handleClick(false)}
      >
        <li><a className="listLinks" href='/eventpage'>BEMdays</a></li>
        <li><a className="listLinks" href='/yildiztalks'>YıldızTalks</a></li>
        <li><a className="listLinks" href='/ssg'>SSG</a></li>
      </ul>;

    }else{
      list = '';
    }
    return <Nav>
      <Container>
        <div className='flex'>
          <a href='/'><img src={logo} alt="logo"/></a>
          <Links>
            <a href="/bizkimiz">Biz Kimiz?</a>
            {/* <span onClick={this.handleClick}>Etkinliklerimiz</span> */}
            <span 
            onMouseEnter={() => this.handleClick(true)}
            onMouseLeave = {() => this.handleClick(false)}
            >Etkinliklerimiz</span>

              {list}
            <a href="/projects">Projelerimiz</a>
            {/* <a href="/iletisim">İletişim</a> */}
            <a href="/sponsorlarimiz">Sponsorlarımız</a>
            {/* <a href="#">Canlı Yayın</a> */}
          </Links>
        </div>

      </Container>
    </Nav>;
  }
}

const Nav = styled.nav`
  position: fixed;
  top: 0;
  left: 0;

  height: 13vh;
  width: 100%;

  background-color: #fff;

  box-shadow: 0 0px 3px #a8a8a8;

  z-index: 999;

  img{
    width: 220px;
  }

  div.flex{
    width: 100%;
    height: 13vh;

    display: flex;
    justify-content: space-between;
    align-items: center;
  }

`

const Links = styled.div`
  text-decoration: none;
  font-size: 18px;
  font-family: "Roboto";
  line-height: 27px;

  position: relative;
  ul{

    display: block;

    position: absolute;
    top: 40px;
    left: 88px;
    
    width: 180px;

    padding: 10px 25px;

    background-color: #fff;;
    border: 1px solid #ececec;
    border-top: 0px;

    color: #212529;

    transition-delay: .1s;
    transition: opacity 2s linear;

    a.listLinks,  a.listLinks:visited{
          color: #082D59;
        }
  }
  > a, span{
    color: #082D59;
    margin-right: 16px;

    cursor: default;
    &:hover{
      color: #365983;
    }
    &:nth-child(2){
      position: relative;
      margin-right: 25px;
      &::after{
        content: '';
        display: block;
        /* height: 1px;
        width: 1px; */

        border-top: 3px solid #fff;
        border-left: 3px solid #fff;
        border-bottom: 3px solid black;
        border-right: 3px solid black;

        position: absolute;
        top: 25%;
        left: 102%;

        transform: rotate(45deg);


      }
      /* &:hover + ul{
        display: block;
        opacity: 1;
      } */

    }
    /* &:last-child{
      display: inline-block;

      padding: 5px 10px;
      
      color: #fff;
      
      background-color: #082D59;
      border-radius: 5px;

      &:hover{
        padding: 4px 8px;
        margin-left: 4px;
      }

    } */
    transition-property: padding, margin;
    transition: .1s linear;
  }

`

