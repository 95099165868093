import React, { Component } from 'react'
import Navbar from '../../components/Navbar'
import Intro from './Intro'
import Amac from './Amac'
import Galery from './Galery'

export default class BEMDays extends Component {
  render() {
    return (
      <>
        <Navbar/>
        <Intro/>
        <Amac/>
        <Galery/>
      </>
      )
  }
}
