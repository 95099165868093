import React, { Component } from 'react';
import styled from "styled-components";
import {AiFillTwitterSquare, AiFillInstagram, AiFillLinkedin, AiFillMail,} from "react-icons/ai";
import { ImLocation } from "react-icons/im";


export default class ContactUs extends Component {
render() {
    return (
        <>
        <Contact>
            <div>
                <div className="mailicon">
                    <AiFillMail />  
                </div>
                <div className="mail">
                    <span>Email:</span>
                    <a href="mailto:maktekytu@gmail.com">
                        maktekytu@gmail.com
                    </a>
                </div>
            </div>

            <div>
                <div className="locIcon">
                    <ImLocation />
                </div>
                <div className="loc">
                    <span>Location</span>
                    <span className='locPath'>Yıldız Teknik Üniversitesi Beşiktaş Kampüsü Makine Teknolojileri Kulübü R Blok 34349 Yıldız/Beşiktaş/İstanbul</span>
                </div>
            </div>

                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3008.8133921083754!2d29.007932430115268!3d41.05120972769688!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x643e986c30a73dbf!2zTWFrdGVrIEt1bMO8cCBPZGFzxLE!5e0!3m2!1str!2str!4v1614637108664!5m2!1str!2str"></iframe>

        </Contact>

        <Footer>
            <a href="https://twitter.com/ytumaktek"><AiFillTwitterSquare/></a>
            <a href="https://www.instagram.com/ytumaktek/"><AiFillInstagram/></a>
            <a href="https://www.linkedin.com/company/ytumaktek"><AiFillLinkedin/></a>
            <p>MAKTEK | TÜM HAKLARI SAKLIDIR</p>
        </Footer>
        </>
    )
  }
}

const Contact = styled.div`
    padding: 0 50px;
    
    color: #4d4d4d;

    font-size: 25px;
    font-family: "Roboto";
    font-weight: 300;

    position: relative;
    .mail, .loc{
        display: inline-block;
    }
    .mailicon, .locIcon{
        display: inline-block;
        /* width: 50%; */
        margin-right: 15px;
    }
    a, .locPath{
        display: block;
        font-size: 16px;
        color: #4d4d4d;

        margin: 32px 0;

        max-width: 745px;
    }
    a:visited{
        color: #212529;
    }
    > div{
        width: 100%;
        display: flex;

        padding: 40px 0 20px 0;
        &:first-child{
            padding-bottom: 0;
        }
    }
    &::after, &::before{
        content: '';
        display: block;

        position: absolute;
        left: 50%;
        margin-left: -650px;

        height: 2px;
        width: 1300px;

        background-color: #0f796f;
    }
    &::after{
        top: 100%;
    }
    iframe{
        width: 100%;
        height: 420px;

        padding: 0 0 20px 0;
    }




`
const Footer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    text-align: center;

    margin: 50px 0; 

    a{
        display: inline-block;
        /* width: 40px;
        height: 40px; */

        margin: 10px 5px;
        color: #000;
        transition: color .2s linear;
        &:hover{
            color: #333333;
        }
    }
    p{
        font-family: 'Roboto';
        font-weight: 500px;
        font-size: 20px;

        color: #c0c0c0;
        letter-spacing: 1.5px;

        width: 100%;
    }
    svg{
        width: 40px;
        height: 40px;
    }


`