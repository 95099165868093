import React, { Component } from 'react'
import styled from 'styled-components'
import Container2 from './Wrapper'

import handImg from './img/hand.png'
import teamImg from './img/mana.png'
import planingImg from './img/pro.png'

export default class Amac extends Component {
render() {
let numeric = 1;
 
return (
<Container2>
    <Amacimiz>
        <div className="reg">
            <h3>KAYIT OLMAK İÇİN</h3>
            <a href='#'>TIKLAYINIZ</a>
        </div>
        <div className="headings">
            <h3>AMACIMIZ</h3>
            <strong>YARINI BUGÜNDEN GÖRMEK İÇİN ÇALIŞIYORUZ</strong>
        </div>
        <div className="textBlocks">
            <div className="textBlock">
                <img src={handImg} alt="" />
                <h4>BUSINESS</h4>
                <p>Mühendislik öğrencileri olarak okulda öğretilmeyen iş dünyasının dinamiklerini ve fırsatlarını sizlere göstermek istiyoruz. </p>
            </div>
            <div className="textBlock">
                <img src={teamImg} alt="" />
                <h4>ENGINEERING</h4>
                <p>Sektörde bulunan lider firmaların mühendisleri ile sizleri buluşturarak o sektörde yaşanan son gelişmeleri sizlere göstermek istiyoruz.</p>
            </div>
            <div className="textBlock">
                <img src={planingImg} alt="" />
                <h4>MANAGEMENT</h4>
                <p>Lider firmaların yönetim kademelerinden çeşitli isimlerle yönetim konusunu derinlemesine ele almak ve henüz öğrenciyken sizlere kurumsal bilinç kazandırmak istiyoruz.</p>
            </div>
        </div>
        <div className="stats">
            <div className="stats__block">
                <span>3</span>
                <span>YIL</span>
                </div>

            <div className="stats__block">
                <span>40</span>
                <span>PANEL</span>
                </div>

            <div className="stats__block">
                <span>97</span>
                <span>PANELİST</span>
                </div>

            <div className="stats__block">
                <span>3000+</span>
                <span>KATILIMCI</span>
                </div>
        </div>
    </Amacimiz>
</Container2>
)
}
}

const Amacimiz = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    text-align: center;
    font-family: 'Roboto', sans-serif;

    margin: auto;

    .textBlocks{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        margin-top: 20px;
        .textBlock{
            margin: 15px;
            padding: 20px;

            border-radius: 20px;

            color: #fff;

            width: 100%;
            max-width: 33.333%;
            background: #c6120c;

            h4{
                font-size: 30px;
                font-weight: 700;
                line-height: 1.2em;

                margin-bottom: 10px;
            }
            img{
                margin: 50px;
            }
            p{
                font-size: 16px;
                font-weight: 500;
                line-height: 1.5em;
            }
        }

    }
    .reg{
        position: relative;

        font-size: 40px;
        text-align: center;
        
        width: 100%;
        max-width: 342px;

        margin: 150px auto;

        &::after, &::before{
            content: '';
            display: block;

            position: absolute;

            height: 3px;
            width: 115%;

            border-radius: 1.5px;

            background-color: #c6120c;
            margin-left: -7.5%;
        }
        &::before{
            top: -10px;

        }
        &::after{
            bottom: -15px;
        }
        a{
            display: block;
            width: 100%;

            padding: 10px 0;

            background-color:#c6120c;

            border-radius:10px;

            text-align: center;
            color: #fff;
            font-size: 30px;
            font-weight: 700;
            letter-spacing: 3px;

            transition: .2s linear;
            transition-property: color, font-size, padding, background;

            &:hover{
                color: #ebebeb;
                font-size: 28px;
                padding: 11px 0 11px;
                background-color:#ad0903;

            }
        }
    }
    h3{
        color: #c6120c;
        font-weight: 500;

        margin-bottom: 10px;
        font-size: 40px;
    }
    strong{
        font-size: 25px;
        line-height: 1.5em;

        color: #212529;
    }
    .stats{
        display: flex;
        justify-content: space-evenly;

        background-color: #f0f0f0;
        border-radius: 25px;

        width: 100%;
        max-width: 1000px;

        margin: 125px 0;
        &__block{        
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;   

            width: 25%;
            height: 150px;

            padding: 40px 50px 30px;

            span{
                &:first-child{
                    font-size: 50px;
                }
                display: block;

                color: #c6120c;
                font-size: 40px;
                font-weight: 700;
                /* letter-spacing: 4px; */

                
            }
        }
    }
`