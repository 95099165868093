import React, { Component } from 'react'
import Nav from '../../components/Navbar.jsx'
import Container from '../../components/Container.jsx';
import Home from './Home.jsx';
import AboutUs from './Aboutus.jsx';
import Projects from './Projects.jsx';
import ContactUs from './Contact.jsx';

import styled from 'styled-components';

export default class Main extends Component {
  render() {
    return (
      <Mains>
      <Nav/>
      
      <Container>
        <Home/>
        <AboutUs/>
      </Container>
      
      <Projects/>
      
      <Container>
        <ContactUs/>
      </Container>
      </Mains>
    )
  }
}

const Mains = styled.div`
  height: 100%;
  width: 100%;
`