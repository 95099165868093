import React, { Component } from 'react';
import styled from 'styled-components';

export default class Container extends Component {
    render() {
        return <Wrapper>
            {this.props.children}
        </Wrapper>;
    }
}

const Wrapper = styled.div`
    /* height: 100%; */
    width: 100%;

    max-width: 1300px;
    margin: 0 auto;

`