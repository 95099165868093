import React, { Component, useLayoutEffect, useRef, useState } from 'react'
import styled from "styled-components"
import about from "./img/upabout.png";
import { Button } from '../../components/Button';

export default function Aboutus() {
  const [show, doShow] = useState({
    itemOne: false,
    // itemTwo: false,
    // itemThree: false
  });
  const ourRef = useRef(null);
    // anotherRef = useRef(null),
    // refThree = useRef(null);

  useLayoutEffect(() => {
    const topPos = element => element.getBoundingClientRect().top;
    const div1Pos = topPos(ourRef.current);
      // div2Pos = topPos(anotherRef.current),
      // div3Pos = topPos(refThree.current);

    const onScroll = () => {
      const scrollPos = window.scrollY + window.innerHeight;
      if (div1Pos < scrollPos) {
        doShow(state => ({ ...state, itemOne: true }));
      } 
      // else if (div2Pos < scrollPos) {
      //   doShow(state => ({ ...state, itemTwo: true }));
      // } else if (div3Pos < scrollPos) {
      //   doShow(state => ({ ...state, itemThree: true }));
      // }
    };

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);
return (
  <AboutUs animate={show.itemOne} ref={ourRef}>
    <img src={about} alt="" />
    <div className="textBlock">
      <h3>Makine Teknolojileri Kulubü</h3>
      <h2>HAKKIMIZDA</h2>
      <p>Makine Teknolojileri Kulübü (MAKTEK) 1997 tarihli resmi kuruluşuyla Yıldız Teknik Üniversitesi'nin en köklü kulüplerinden biri olma geçmişine ve tek bir bölüme odaklanmayıp, günümüz koşullarına uygun yenilikçi, girişimci, teknolojiyi takip eden multidisipliner öğrenciler yetiştirilmesi vizyonuna sahip bir kulüptür.</p>
        {/* <div className="btn btn_1">
          <a href="#">Daha Fazlası</a>
        </div> */}
        <Button>Daha Fazlası</Button>

    </div>
</AboutUs>
  )
}


// export default class Aboutus extends Component {
//   render() {

//     return (
//       <AboutUs>
//         <img src={about} alt="" />
//         <div className="textBlock">
//           <h3>Makine Teknolojileri Kulubü</h3>
//           <h2>HAKKIMIZDA</h2>
//           <p>Makine Teknolojileri Kulübü (MAKTEK) 1997 tarihli resmi kuruluşuyla Yıldız Teknik Üniversitesi'nin en köklü kulüplerinden biri olma geçmişine ve tek bir bölüme odaklanmayıp, günümüz koşullarına uygun yenilikçi, girişimci, teknolojiyi takip eden multidisipliner öğrenciler yetiştirilmesi vizyonuna sahip bir kulüptür.</p>
//           <div className="wrapper">
//             <div className="btn btn_1">
//               <a href="#">Daha Fazlası</a>
//             </div>
//           </div>	
//           {/* <button>Daha Fazlası</button> */}
//         </div>
//       </AboutUs>
//     )
//   }
// }

const AboutUs = styled.div`
  height: 100vh;
  width: 100%;

  display: flex;
  justify-content: space-around;
  align-items: center;

  /* transform: translateX(${({ animate }) => (animate ? "0" : "-100vw")}); */
  transform: scale(${({ animate }) => (animate ? "100%" : "20%")});
  opacity: ${({animate}) => (animate ? "1" : "0")};

  transition: 2s ease;
  transition-property: transform, opacity;
  img{
    max-width: 400px;
  }
  .textBlock{
    max-width: 695px;

    text-align: left;
    font-family: "Roboto", sans-serif;
    line-height: 1.2;

    h2{
        font-size: 55px;
        font-weight: 600;
        color: #212529;

        margin-bottom: 20px;
    }

    h3{
        font-size: 40px;
        font-weight: 300;
        color: #3a3a3a;

        margin-bottom: 20px
    }
    p{
        font-size: 17px;
        line-height: 22px;
        letter-spacing: 0.5px;
        font-weight: 300;
        color: #353535;

        padding-bottom: 20px;
        margin-bottom: 20px;
    }

      /* .btn{
        border: 2px solid #0f796f;
        width: 200px;
        height: 45px;
        text-align: center;
        line-height: 45px;
        position: relative;
        overflow: hidden;

        a{
          color: #0f796f;
          text-decoration: none;
          text-transform: uppercase;
          letter-spacing: 5px;
          display: block;
          position: relative;
          z-index: 2;
          font-weight: bold;
          transition: all 0.5s ease;

          &:hover{
            color: #fff;
          }
        }
        &::before{
          content: "";
          position: absolute;
          top: -60px;
          left: 0;
          width: 100%;
          height: 100%;
          background: #0f796f;
          transform: skewY(10deg);
          transition: all 0.5s ease;
        }
        &::after{
          content: "";
          position: absolute;
          top: 60px;
          left: 0;
          width: 100%;
          height: 100%;
          background: #0f796f;
          transform: skewY(10deg);
          transition: all 0.5s ease;
        }
        &:hover:before{
          top: -20px;
        }
        &:hover:after{
          top: 20px;
        }
      } */
  }
`
