import React from 'react'
import styled from 'styled-components'

export const Button = (props) => {
return (
    <Btn>{props.children}</Btn>
)
}

const Btn = styled.a`
/* .btn{ */
/* margin: 15px; */
border: 2px solid #0f796f;
width: 200px;
height: 45px;
text-align: center;
line-height: 45px;
position: relative;
overflow: hidden;

/* a{ */
    color: #0f796f;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 5px;
    display: block;
    position: relative;
    z-index: 2;
    font-weight: bold;
    transition: all 0.5s ease;

    &:hover{
    /* color: #30333A; */
    color: #fff;
    }
/* } */
&::before{
    content: "";
    position: absolute;
    top: -60px;
    left: 0;
    width: 100%;
    height: 100%;
    background: #0f796f;
    transform: skewY(10deg);
    transition: all 0.5s ease;

    z-index: -1;    
}
&::after{
    content: "";
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    height: 100%;
    background: #0f796f;
    transform: skewY(10deg);
    transition: all 0.5s ease;

    z-index: -1;
}
&:hover:before{
    top: -20px;
}
&:hover:after{
    top: 20px;
}
/* } */
`