import React from 'react'
import styled from 'styled-components'

export const Projectblock = (props) => {
  const Block = styled.div`
  position: relative;

  margin: 30px auto;
  display: inline-block;
  width: 45%;
  min-width: 400px;
  min-height: 200px;

  background-color: rgba(255, 255, 255, 1);

  border-radius: 15px;


  
  .trans{
    &__bg{
    background: url(${props.img}) #fff no-repeat;
    background-position: top center;
    background-size: cover;

    border-radius: 15px;

    width: 100%;
    height: 100%;
    min-height: 200px;

    opacity: .09;

    position: absolute;
    top: 0;
    left: 0;

    display: block;
    z-index: 0;
    }
  }

  h2{
    text-align: center;
    margin: 15px 0 15px 0 ;

    font-size: 25px;
    font-family: 'Roboto', sans-serif;
    font-weight: 600; 

    color: #212529;
  }
  p{
    /* margin-left: 125px; */
    text-indent: 25px;
    line-height: 1.5;
  }

  img{
    /* position: absolute; */

    max-height: 100px;
    max-width: 125px;

    margin: 10px; 

    z-index: 3;
  }
  .flexbox{
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    align-items: center;

    width: 100%;    
    min-width: 145px;
    height: 100%;
    min-height: 200px;

    &__img{
      display: flex;
      justify-content: center;
      align-items: center;    
      
      min-height: 100px;
      /* height: 50%; */
    }
  }
  .flex1{
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    height: 100%;
    min-height: 200px;
    z-index: 2;
    .text{
      margin: 0 10px;
    }
  }
` 
  return (
    <Block>
      <div className='trans'>
        <div className="trans__bg">

        </div>
      </div>
      <div className="flex1">
        <div className="flexbox">
          <div className="flexbox__img">
            <img src={props.img} alt="" />
          </div>
          <Button href={props.link}>Daha fazlasi</Button>
        </div>
        <div className='text'>
          <h2>{props.name}</h2>
          <p>{props.about}</p>
        </div>
      </div>
    </Block>
  )
}

const Button = styled.a`
  display: inline-block;
  border: 2px solid #4d4d4d;
  padding: 8px 15px;

  color: #4d4d4d;

  text-align: center;
  margin: auto;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;

  transition: .2s linear;
  transition-property: border, color;

  cursor: pointer;

  &:hover{
    border: 2px solid #3f948c;

    color: #3f948c;
  }
`