import React from 'react'
import styled from 'styled-components'
// import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Navbar from '../../components/Navbar'

// import bgImg from './img/bg.png'
import bgImg from './img/bg2.jpg'
import { Projectblock } from './Projectblock'

import img1 from './img/sp1.png'
import img2 from './img/sp2.png'
import img3 from './img/sp3.png'
import img4 from './img/sp4.png'
import img5 from './img/sp5.png'
import img6 from './img/sp6.png'

const about = {
  Racing : '2011 yılında Yıldız Teknik Üniversitesi Makine Teknolojileri Kulübü bünyesinde kurulan YTU Racing, her sene sıfırdan tasarlayıp ürettiği içten yanmalı, elektrikli ve otonom yarış araçlarıyla, Avrupa’da Formula Student yarışlarına ...',
  Roket : '2017 yılında Yıldız Teknik Üniversitesi Makine Teknolojileri Kulübü bünyesinde kurulan Yıldız Roket Takımı, havacılık ve uzay alanlarında yaptığı projeler ve katıldığı yarışmalar ile Türkiyede okulumuzu, Dünya’da ise ülkemizi gururla ...',
  Sigma : 'YTU Sigma Aeronautics, 2012 yılında Yıldız Teknik Üniversitesi’nin mühendislik öğrencileri tarafından dünyadaki en prestijli havacılık topluluklarından olan AIAA (American Institute of Aeronautics and Astronautics) kurumunun her sene ...',
  Reyard : 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. In, omnis nemo, atque perferendis repellat tenetur adipisci rem esse impedit necessitatibus modi unde nostrum labore doloremque saepe quasi? Dolorum, voluptate unde!',
  AI : 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. In, omnis nemo, atque perferendis repellat tenetur adipisci rem esse impedit necessitatibus modi unde nostrum labore doloremque saepe quasi? Dolorum, voluptate unde!',
  Sek : 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. In, omnis nemo, atque perferendis repellat tenetur adipisci rem esse impedit necessitatibus modi unde nostrum labore doloremque saepe quasi? Dolorum, voluptate unde!',
}

export const Projects = () => {
  return (
    <>
      <Navbar/>
      <Bg/>
      <Project>
        <Projectblock about = {about.Racing} link = {'/ytu-racing'} img = {img6} name = "YTU Racing"/>
        <Projectblock about = {about.Roket} link = {'/roket'} img = {img1} name = "Yıldız Roket Takımı"/>
        <Projectblock about = {about.Reyard} link = {'/reyard'} img = {img2} name = "REYARD"/>
        <Projectblock about = {about.Sigma} link = {'/sigma'} img = {img3} name = "Sigma"/>
        <Projectblock about = {about.AI} link = {'/AI'} img = {img4} name = "Yapay Zeka Topluluğu"/>
        <Projectblock about = {about.Sek} link = {'/sektore-bir-yildiz'} img = {img5} name = "Sektöre Bir Yıldız"/>
      </Project>

      {/* <BrowserRouter>
        <Routes>
          <Route exact path = "/ytu-racing" element={<Test/>}/>
          <Route exact path = "/eventpage" element={<Test/>}/>
          <Route exact path = "/projects" element={<Test/>}/>
          <Route exact path = "/iletisim" element={<Test/>}/>
          <Route exact path = "/sponsorlarimiz" element={<Test/>}/>
        </Routes>
      </BrowserRouter> */}
    </>
  )
}

const Project = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  width: 100%;
  max-width: 1100px;
  margin: 13vh auto 0;
  padding: 30px 0 0 0;

  /* background-color: #000; */
`
const Bg = styled.div`
  width: 100%;
  height: 87vh;

  position: fixed;
  top: 0;
  left: 0;

  margin-top: 13vh;

  background: url(${bgImg}) no-repeat center;
  background-size: cover;

  z-index: -3;
`