import Main from "./pages/Main/Main.jsx";
import About from "./pages/About/About.jsx";
import BEMDays from "./pages/BEMdays/BEMDays.jsx";
import { Projects } from "./pages/Projects/Projects.jsx";
// import { Projects } from "./pages/ProjectsV2/Projects.jsx";
import { Iletisim } from "./pages/Iletisim/Iletisim.jsx";
import { Sponsorlar } from "./pages/Sponsorlar/Sponsorlar.jsx";
import { SSG } from "./pages/SSG/SSG.jsx";

import { css } from "styled-components";
import React, { useState, useEffect } from "react";
import HashLoader from "react-spinners/HashLoader";

import { BrowserRouter, Route, Routes } from "react-router-dom";
// import HashLoader from "react-spinners/HashLoader";
// import { css } from "@emotion/core";


function App() {
  const override = css`
  display: block;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 48%;
  border-color: red;
`;

const [loading, setloading] = useState(false);

useEffect(() => {
  setloading(true);
  setTimeout(() => {
    setloading(false);
  }, 1000);
}, []);

  return (
    <>
    {loading ? (
        <HashLoader css={override}></HashLoader>
      ) : (
<BrowserRouter>
    <Routes>
      <Route exact path = "/" element={<Main/>}/>
      <Route exact path = "/bizkimiz" element={<About/>}/>
      <Route exact path = "/eventpage" element={<BEMDays/>}/>
      <Route exact path = "/projects" element={<Projects/>}/>
      <Route exact path = "/iletisim" element={<Iletisim/>}/>
      <Route exact path = "/sponsorlarimiz" element={<Sponsorlar/>}/>
      <Route exact path = "/ssg" element={<SSG/>}/>

      <Route exact path = "/ytu-racing" element={<About/>}/>
      <Route exact path = "/ssg" element={<SSG/>}/>
      <Route exact path = "/ssg" element={<SSG/>}/>
      <Route exact path = "/ssg" element={<SSG/>}/>
      <Route exact path = "/ssg" element={<SSG/>}/>
      <Route exact path = "/ssg" element={<SSG/>}/>

    </Routes>
</BrowserRouter>)}

</>
  );
}

export default App;
