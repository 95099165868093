import React, { Component } from 'react'
import styled from 'styled-components'
import Container2 from './Wrapper'

import bgimg from './img/homearka.png'
import bemlogo from './img/bemlogo.png'
import introimg from './img/bemday.png'


export default class Intro extends Component {
    render() {
    return (
        <Intra>
            <Container2>
                <div className="flex">
                    <div>
                        <div className="bemlogo">
                            <img src={bemlogo} alt="" className='bemlogo'/>
                            <strong>#GELECEKTENİLHAMAL</strong>
                        </div>
                        <p>İsmini Business Engineering Management kelimelerinin baş harflerinden alan etkinliğimizde teknoloji alanında hizmet veren firmaların konularında en etkili isimleriyle geleceğin konularını işlemeyi amaçlıyoruz.Geleceğin konularını işlerken ülkemizin öğrencilerine bu konularda bilinç kazandırmak ve onlara teknolojik trendler hakkında bilgi vermek istiyoruz.</p>
                    </div>

                    <img src={introimg} alt="" className='introimg'/>
                </div>
                {/* <div className="reg">
                    <h3>KAYIT OLMAK İÇİN</h3>
                    <a href='#'>TIKLAYINIZ</a>
                </div> */}
            </Container2>
        </Intra>
    )
    }
}
    
const Intra = styled.div`
    margin-top: 13vh;
    width: 100%;
    height: 87vh;

    background: url(${bgimg}) no-repeat center;
    background-position: 100% 0%;
    background-size: 97vh auto;

    font-family: 'Roboto', sans-serif;
    .flex{
        display: flex;
        justify-content: space-around;
        align-items: center;

        height: 87vh;
        
        p{
            max-width: 540px;

            margin-top: 40px;
            
            color: #212529;

            line-height: 1.5em;
            font-size: 18px;
            font-weight: 500;


        }

    }
    .introimg{
        max-width: 540px;
    }
    .bemlogo{
        max-width: 380px;

        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        strong{
            width: 100%;

            padding: 10px 0;

            background-color:#c6120c;

            border-radius:10px;

            text-align: center;
            color: #fff;

            font-size: 30px;
            
        }
    }
    /* .reg{
        position: relative;

        font-size: 40px;
        text-align: center;
        
        width: 100%;
        max-width: 342px;

        margin: 150px auto;

        &::after, &::before{
            content: '';
            display: block;

            position: absolute;

            height: 3px;
            width: 115%;

            border-radius: 1.5px;

            background-color: #c6120c;
            margin-left: -7.5%;
        }
        &::before{
            top: -10px;

        }
        &::after{
            bottom: -15px;
        }

        h3{
            color: #c6120c;
            font-weight: 500;

            margin-bottom: 10px;
        }
        a{
            display: block;
            width: 100%;

            padding: 10px 0;

            background-color:#c6120c;

            border-radius:10px;

            text-align: center;
            color: #fff;
            font-size: 30px;
            font-weight: 700;
            letter-spacing: 3px;
        }
    } */
`