import React, { Component, useEffect, useLayoutEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import Container from '../../components/Container'

import Aos from 'aos'
import 'aos/dist/aos.css'

import img1 from './img/ab1.png';
import img2 from './img/ab2.png';
import img3 from './img/ab3.png';

import person1 from './img/person1.png'
import person2 from './img/person2.png'
import person3 from './img/person3.png'
import person4 from './img/person4.png'
import person5 from './img/person5.png'
import person6 from './img/person6.png'
import person7 from './img/person7.png'
import person8 from './img/person8.jpg'
import person9 from './img/person9.png'

export default function Textblocks() {
   // const [show, doShow] = useState({
   //    itemOne: false,
   //    itemTwo: false,
   //    itemThree: false
   // });
   // const ourRef = useRef(null),
   //    anotherRef = useRef(null),
   //    refThree = useRef(null);

   // useLayoutEffect(() => {
   //    const topPos = element => element.getBoundingClientRect().top;
   //    const div1Pos = topPos(ourRef.current),
   //    div2Pos = topPos(anotherRef.current),
   //    div3Pos = topPos(refThree.current);

   //    const onScroll = () => {
   //    const scrollPos = window.scrollY + window.innerHeight;
   //    if (div1Pos < scrollPos) {
   //       doShow(state => ({ ...state, itemOne: true }));
   //    } else if (div2Pos < scrollPos) {
   //       doShow(state => ({ ...state, itemTwo: true }));
   //    } else if (div3Pos < scrollPos) {
   //       doShow(state => ({ ...state, itemThree: true }));
   //    }
   //    };

   //    window.addEventListener("scroll", onScroll);
   //    return () => window.removeEventListener("scroll", onScroll);
   // }, []);
   useEffect(() => {
      Aos.init({duration: 2000})
   })
return (
   <Container>
   <TextedBlock>
      <div data-aos="fade-right" className="pack pack1">
         <img src={img1} alt="" />
         <div>
            <h2>Hakkımızda</h2>
            <p>{str1}</p>
         </div>
      </div>

      <div data-aos="fade-left" className="pack pack2">
         <div>
            <h2>Vizyon</h2>
            <p>{str2}</p>
            {list}
         </div>
         <img src={img2} alt="" />
      </div>

      <div data-aos="fade-right" className="pack pack3">
         <img src={img3} alt="" />
         <div>
            <h2>Misyon</h2>
            <p>{str3}</p>
         </div>
      </div>
   </TextedBlock>

   <Team>
      <div data-aos="fade-up" className="support">
         <h2>Akademik Danışmanlar</h2>
         <div className='flex'>
            <div className='flex__block'>
               <img src={person8} alt="" />
               <div>Doç. Dr. ALP TEKİN <br/> ERGENÇ</div>   
            </div>
            <div className='flex__block'>
               <img src={person9} alt="" />
               <div>Prof.Dr.ÖZDEN <br/> AĞRA</div>   
            </div>
         </div>
      </div>

      <div data-aos="fade-up" className="yonetim">
         <h2>Yönetim Kurulu</h2>
         
         <div className="flex">
            <div className="flex__block">
               <img src={person1} alt="" />
               <div>Ismet <br/>KOCABAŞ</div>
               <div className='hover'>Başkan Yardımcısı</div>
            </div>
            <div className="flex__block">
               <img src={person2} alt="" />
               <div>Ekin <br/>ELDELEKLİOĞLU</div>
               <div className='hover'>Kurul Başkanı</div>
            </div>
            <div className="flex__block">
               <img src={person3} alt="" />
               <div>Mert <br/>DEVRİM</div>
               <div className='hover'>Sayman</div>
            </div>
            <div className="flex__block">
               <img src={person4} alt="" />
               <div>Bahadır <br/>ÇEKİÇ</div>
               <div className='hover'>BEMdays Koordinatörü</div>
            </div>
         </div>

         <div className="flex">
            <div className="flex__block">
               <img src={person5} alt="" />
               <div>Ecem <br/>ELMAS</div>
               <div className='hover'>Genel Sekreter</div>
            </div>
            <div className="flex__block">
               <img src={person6} alt="" />
               <div>Berkin <br/>KADİROĞLU</div>
               <div className='hover'>PR Koordinatörü</div>
            </div>
            <div className="flex__block">
               <img src={person7} alt="" />
               <div>M. Emin <br/>ÇELİK</div>
               <div className='hover'>Eğitim Koordinatörü</div>
            </div>
         </div>
      </div>
   </Team>
</Container>
)
}


// export default class Textblocks extends Component {
//   render() {
//     return (
//    <Container>
//       <TextedBlock>
//          <div className="pack">
//             <img src={img1} alt="" />
//             <div>
//                <h2>Hakkımızda</h2>
//                <p>{str1}</p>
//             </div>
//          </div>

//          <div className="pack">
//             <div>
//                <h2>Vizyon</h2>
//                <p>{str2}</p>
//                {list}
//             </div>
//             <img src={img2} alt="" />
//          </div>

//          <div className="pack">
//             <img src={img3} alt="" />
//             <div>
//                <h2>Misyon</h2>
//                <p>{str3}</p>
//             </div>
//          </div>
//       </TextedBlock>

//       <Team>
//          <div className="support">
//             <h2>Akademik Danışmanlar</h2>
//             <div className='flex'>
//                <div className='flex__block'>
//                   <img src={person8} alt="" />
//                   <div>Doç. Dr. ALP TEKİN <br/> ERGENÇ</div>   
//                </div>
//                <div className='flex__block'>
//                   <img src={person9} alt="" />
//                   <div>Prof.Dr.ÖZDEN <br/> AĞRA</div>   
//                </div>
//             </div>
//          </div>

//          <div className="yonetim">
//             <h2>Yönetim Kurulu</h2>
            
//             <div className="flex">
//                <div className="flex__block">
//                   <img src={person1} alt="" />
//                   <div>Ismet <br/>KOCABAŞ</div>
//                   <div className='hover'>Başkan Yardımcısı</div>
//                </div>
//                <div className="flex__block">
//                   <img src={person2} alt="" />
//                   <div>Ekin <br/>ELDELEKLİOĞLU</div>
//                   <div className='hover'>Kurul Başkanı</div>
//                </div>
//                <div className="flex__block">
//                   <img src={person3} alt="" />
//                   <div>Mert <br/>DEVRİM</div>
//                   <div className='hover'>Sayman</div>
//                </div>
//                <div className="flex__block">
//                   <img src={person4} alt="" />
//                   <div>Bahadır <br/>ÇEKİÇ</div>
//                   <div className='hover'>BEMdays Koordinatörü</div>
//                </div>
//             </div>

//             <div className="flex">
//                <div className="flex__block">
//                   <img src={person5} alt="" />
//                   <div>Ecem <br/>ELMAS</div>
//                   <div className='hover'>Genel Sekreter</div>
//                </div>
//                <div className="flex__block">
//                   <img src={person6} alt="" />
//                   <div>Berkin <br/>KADİROĞLU</div>
//                   <div className='hover'>PR Koordinatörü</div>
//                </div>
//                <div className="flex__block">
//                   <img src={person7} alt="" />
//                   <div>M. Emin <br/>ÇELİK</div>
//                   <div className='hover'>Eğitim Koordinatörü</div>
//                </div>
//             </div>
//          </div>
//       </Team>


//    </Container>
//     )
//   }
// }

const TextedBlock = styled.div`
   padding: 13vh 0 0 0;

   font-family: "Montserrat";
   font-size: 15px;

   line-height: 24px;
   color: #212529;

   .pack{
      display: flex;
      justify-content: start;
      align-items: center;

      height: 87vh;

      /* transition: transform 1s; */
      div{
         padding: 20px;
         
         ul{
            li{
               text-indent: 20px;
               position: relative;
               margin-bottom: 10px;
               &:first-child{
                  margin-top: 10px;
               }
               &::before{
                  content: '';
                  display: inline-block;

                  position: absolute;
                  top: 25%;
                  left: 0;

                  height: 2px;
                  width: 2px;
                  background-color: #212529;
                  /* border: 1px solid #212529; */
                  border-radius: 50%;
               }
            }
         }
      }
      h2{
         display: inline-block;

         position: relative;

         font-size: 32px;
         font-weight: 500;
         
         text-transform: uppercase;
         
         margin-bottom: 8px;

         line-height: 1.2;
         
         &::after{
         
            content: '';
            display: block;

            height: 8px;
            width: 110%;

            position: absolute;
            top: 43%;
            left: -5%;

            z-index: -1;

            background-color: rgba(155, 189, 185, .6);
         }
      }
      img{
         width: 418px;
      }
      
   }
`

const Team = styled.div`
   h2{
      font-family: "Poppins", sans-serif;
      font-size: 38px;
      font-weight: 100;

      color: #212529;
      line-height: 1.2;
      letter-spacing: 2px;
      text-align: center;
   }
   .yonetim div.flex__block{
      &:hover .hover{
         opacity: 1;
         transform: translateY(0);
      }
      .hover{
         display: block;
         opacity: 0;

         position: absolute;
         top: 77%;
         left: 0;

         transform: translateY(20px);

         background-color: #fff;

         transition: opacity .1s linear, transform .2s linear;
      }

   }
   .flex{
      display: flex;
      justify-content: center;
      margin-bottom: 50px;

      
      &__block{
         margin: 20px 15px;

         width: 270px;

         border-radius: 15px;
         box-shadow: 0 5px 5px #ebebeb;
         overflow: hidden;

         position: relative;
         img{
            width: 270px;
         }
         div{
            width: 100%;
            height: 100px;

            padding: 20px;
            margin: 0 auto;

            display: flex;
            justify-content: center;
            align-items: center;

            text-align: center;

            color: #212529;

            font-family: 'Roboto', sans-serif;
            font-weight: 700;
            font-size: 17px;
            line-height: 2;
         }
      }
   }
`

const str1 = "Kulübümüz 20 yılı aşkın etkinlik tecrübesiyle şu ana kadar büyüklü küçüklü, sektörün ve sanayimizin nabzını tuttuğumuz gelecek yıllarda hayatımızda olması muhtemel konuları alanında uzman kişiler ile değerlendirdiğimiz yüzlerce etkinlik gerçekleştirmiştir. Bunun yanında verilen eğitim imkanlarıyla, ARGE merkezlerine ya da fabrikalara yaptığı teknik geziler ile teknolojinin yaratıldığı ve uygulandığı ortamları izleme fırsatı sunmaktadır. En önemli hizmetlerinden biri olan üniversite sıralarında aldığımız teoride kalmış bilgileri uygulamaya dökme imkanı veren uluslararası projeleriyle öğrencilerin aradığı her şeyi bir araya getiren bir oluşumdur."

const str2 = "YTÜ Makine Teknolojileri Kulübü olarak Vizyonumuz:"

const str3 = "Sürekli değişen ve gelişen dünyamızda günümüz teknolojilerine uyum sağlayan, yeniliğin ve inovasyonun öncüsü,dinamik ve multidisipliner mühendisler yetiştirebilmek amacıyla çeyrek asla dayanan proje ve etkinliklerimize her geçen yıl yenisini ekleyerek devam ediyoruz."

const list = <ul>
<li>Bilim ve teknolojideki gelişmeleri takip edip uygulayarak,mühendislik etiğine uygun şekilde yenilik ve inovasyonların öncüsü olabilen,</li>
<li>Takımlarımızın derste öğrendiklerini pratiğe dökerek uygulayabildiği projeleriyle;ülkemizdeki AR-GE çalışmalarına yenilerini ekleyerek teknoloji ve sanayimizin gelişimine katkı sağlayan,</li>
<li>Okulumuzdaki ve diğer okullardaki öğrenci kulüpleriyle sağlıklı ilişkiler kurarak,bilgi alışverişi ve dinamiği sağlayabilen,</li>
<li>Sadece kendi bölümüyle sınırlı kalmadan günümüz koşullarına uygun teknolojiyi takip eden, inovatif düşünce yapısına sahip,nitelikli mühendisleri bünyesinde barındıran bir öğrenci kulübü olmaktır.</li>
</ul>

