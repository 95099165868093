import React from 'react'
import styled from 'styled-components'
import Navbar from '../../components/Navbar'
import bg from './1.jpg'

import img1 from '../Projects/img/sp1.png'
import img2 from '../Projects/img/sp2.png'
import img3 from '../Projects/img/sp3.png'
import img4 from '../Projects/img/sp4.png'
import img5 from '../Projects/img/sp5.png'
import img6 from '../Projects/img/sp6.png'

export const Sponsorlar = () => {
  return (
    <>
      <Navbar/>
      <Sponsorlarimiz>
        <div className="bg"></div>
        <div className='content'>
          <div className="content__box">
            <h2 className='gold'>Altın Sponsorlarımız</h2>
            <div className="logo-grid">
              <img src={img1} alt="" />
              <img src={img2} alt="" />
              <img src={img3} alt="" />
              <img src={img4} alt="" />
              <img src={img5} alt="" />
              <img src={img6} alt="" />
            </div>
          </div>

          <div className="content__box">
            <h2 className='silver'>Gümüş Sponsorlarımız</h2>
            <div className="logo-grid">
              <img src={img1} alt="" />
              <img src={img2} alt="" />
              <img src={img3} alt="" />
              <img src={img4} alt="" />
              <img src={img5} alt="" />
              <img src={img6} alt="" />
            </div>
          </div>

          <div className="content__box">
            <h2 className='bronze'>Bronz Sponsorlarımız</h2>
            <div className="logo-grid">
              <img src={img1} alt="" />
              <img src={img2} alt="" />
              <img src={img3} alt="" />
              <img src={img4} alt="" />
              <img src={img5} alt="" />
              <img src={img6} alt="" />
            </div>
          </div>
        </div>
      </Sponsorlarimiz>
    </>
    )
}
const Sponsorlarimiz = styled.div`
  margin-top: 13vh;
  
  width: 100%;
  
  .content{
    width: 100%;
    max-width: 1200px;
    height: 100%;
    
    margin: 0 auto;
    padding-top: 1px;

    &__box{
      width: 100%;
      min-height: 100px;

      border-radius: 15px;

      background-color: rgba(255, 255, 255, 1);

      margin: 30px 0;
      padding-top: 20px;

      h2{
        text-align: center;
        font-size: 44px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 600;
        
        text-shadow: 0px 1px 1px #2d2d2d;
        
        &.gold{
          color: #ffcc66;
        }
        &.silver{
          color: #cdcdcd;

        }
        &.bronze{
          color: #cd7f32;

        }
      }

      .logo-grid{
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-wrap: wrap;
        img{
          max-width: 20%;
          margin:20px;
        }
      }

    }
  }
  .bg{
    position: fixed;
    top: 0;
    left: 0;
    
    margin-top: 13vh;
    background: url(${bg}) no-repeat center;
    background-size: cover;

    opacity: .8;

    width: 100%;
    height: 87vh;

    z-index: -1;
  }
`
