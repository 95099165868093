import React, { Component } from 'react'
import Container from '../../components/Container'
import Navbar from '../../components/Navbar'
import Textblocks from './Textblocks'

export default class About extends Component {
  render() {
    return (
      <>
        <Navbar/> {/*13vh  */}
        <Textblocks/>
      </>
    )
  }
}
