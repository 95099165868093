import React, { Component } from 'react';
import styled from 'styled-components';
import img from './img/slider1.jpg';

export default class Home extends Component {
  render() {
    return <Main>
        <div className="headings">
            <h1>MAKİNE <br/>TEKNOLOJİLERİ KULÜBÜ</h1>
            <h2>Türkiye'nin EN AKTİF Bilim ve Teknoloji Kulübü</h2>
            <p>'Ya Ar-Ge Ya Sömürge!'</p>
        </div>
        <img src={img} alt="" />
    </Main>;
  }
}

const Main = styled.div`
    padding: 13vh 0 0 0;

    height: 100vh;

    display: flex;
    justify-content: space-around;
    align-items: center;
    div{
        text-align: center;
        font-family: "Poppins", sans-serif;
        line-height: 1.2;

        h1{
            font-size: 55px;
            font-weight: 600;
            color: #212529;

            margin-bottom: 18px;
        }

        h2{
            font-size: 30px;
            font-weight: 200;
            color: #3a3a3a;

            margin-bottom: 33px
        }
        p{
            font-size: 20px;
            font-family: monospace; 
            color: #0f796f;

            margin: 0 auto;
            padding-right: 1px;

            width: 23ch;

            white-space: nowrap;
            overflow: hidden;

            border-right: 2px solid #0f796f;

            animation:  blink .75s step-end infinite, 
                        typing 5s steps(23) infinite;
        }
        @keyframes blink{
            0%{
                border-color: #0f796f;
            }
            50%{
                border-color: transparent;
            }
            100%{
                border-color: #0f796f;
            }
        }
        @keyframes typing{
            0%{width: 9ch;}

            20%{width: 23ch;}
            50%{width: 23ch;}

            70%{width: 9ch;}
            100%{width: 9ch;}
        }
    }

`