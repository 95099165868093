import React, { Component } from 'react'
import styled from "styled-components";
// import { Carousel } from "react-bootstrap";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
// import Carousel from "react-elastic-carousel";
import Wrapper from '../../components/Container.jsx'

import img1 from "./img/sp1.png";
import img2 from "./img/sp2.png";
import img3 from "./img/sp3.png";
import img4 from "./img/sp4.png";
import img5 from "./img/sp5.png";
import img6 from "./img/sp6.png";

export default class Project extends Component {
  render() {
    return (
    <Projects>
      <Wrapper>
      <h2>Projelerimiz</h2>


        <AliceCarousel 
        autoPlay = {true}
        mouseTracking 
        items={items}
        responsive = {responsive}
        infinite={true}
        autoPlayInterval={2000}
        animationDuration={900}
        disableButtonsControls={false}
        touchTrackingEnabled={false}
        />
      </Wrapper>
    </Projects>
    )
  }
}

const Projects = styled.div`
    height: 320px;
    width: 100%;

    padding:25px 50px;
    margin-bottom: 100px;

    background-color: #45ccbe31;

    position: relative;
    h2{
      text-align: center;
      text-transform: uppercase;
      line-height: 1.2;
      
      font-family: "Roboto", sans-serif;
      font-size: 35px;
      font-weight: 300;
      color: #3a3a3a;

      margin-bottom: 20px
    }

    img{
      display: inline-block;
      width: 160px;
    }
    .imgBlock{
      height: 160px;
      width: 160px;

      display: flex;
      justify-content: center;
      align-items: center;

      margin: 0 auto;
    }
    .alice-carousel__dots-item{
      background-color: #fff;
      &:hover{
        background-color: #0f796f;
      }
      &.__active{
        background-color: #0f796f;
      }
    }
    .alice-carousel{
      width: 100%;
    }
    .alice-carousel__prev-btn{
      position: absolute;
      top: 25%;
      width: 0;
      /* left: -50%; */
      transform: scaley(2);
    }
    .alice-carousel__next-btn{
      position: absolute;
      top: 25%;
      width: 0;
      right: 0%;
      transform: scaley(2);

      /* width: 20px; */
      /* &-wrapper{
      } */
    }
    span{
      color: #0f796f;
    }

`
const handleDragStart = (e) => e.preventDefault();

const responsive = {
  0: { items: 1 },
  568: { items: 2 },
  1024: { items: 4 },
};
const items = [
  <div className="imgBlock"><img src={img1} alt="" onDragStart={handleDragStart}/></div>,
  <div className="imgBlock"><img src={img2} alt="" onDragStart={handleDragStart}/></div>,
  <div className="imgBlock"><img src={img3} alt="" onDragStart={handleDragStart}/></div>,
  <div className="imgBlock"><img src={img4} alt="" onDragStart={handleDragStart}/></div>,
  <div className="imgBlock"><img src={img5} alt="" onDragStart={handleDragStart}/></div>,
  <div className="imgBlock"><img src={img6} alt="" onDragStart={handleDragStart}/></div>
]



