import React, { Component } from 'react'
import styled from 'styled-components'

import img1 from './img/bem1.JPG'
import img2 from './img/bem2.jpg'
import img3 from './img/bem3.jpg'
import img4 from './img/bem4.jpg'
import img5 from './img/bem5.JPG'
import img6 from './img/bem6.JPG'
import img7 from './img/bem7.JPG'

export default class Galery extends Component {
render() {
return (
    <Galeri>
        <h3>GALERİ</h3>
        <Imggrid>
            <div className='div1'></div>
            <div className='div2'></div>
            <div className='div3'></div>
            <div className='div4'></div>
            <div className="second_grid">
                <div className='div5'></div>
                <div className='div6'></div>
                <div className='div7'></div>

            </div>
        </Imggrid>
            {/* <div className="row row1">
                <div className="img1"><img src={img6} alt="" /></div>
                <div className="img2"><img src={img2} alt="" /></div>
            </div>

            <div className="row row2">
                <div className="img3"><img src={img3} alt="" /></div>
                <div className="img4"><img src={img4} alt="" /></div>
            </div>

            <div className="row row3">
                <div className="img5"><img src={img5} alt="" /></div>
                <div className="img6"><img src={img1} alt="" /></div>
                <div className="img7"><img src={img7} alt="" /></div>
            </div> */}
    </Galeri>
)
}
}

const Galeri = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    text-align: center;
    font-family: 'Roboto', sans-serif;

    margin-bottom: 100px;

    h3{
        color: #212529;
        font-size: 30px;
        font-weight: 700;
        line-height: 1.2em;

        margin-bottom: 15px;
    }
`
// const Imggrid = styled.div`
//     display: flex;
//     /* grid-template-columns: repeat(5, 1fr);
//     grid-template-rows: repeat(5, 1fr); */
//     justify-content: center;
//     flex-wrap: wrap;
//     align-items: center;

//     width: 100%;
//     max-width: 1010px;
//     height: 1000px;

//     margin: auto;
//     div{
//         display: flex;
//         justify-content: center;
//         align-content: center;
//         /* align-items: center; */

//         overflow: hidden;

//         width: 100%;
//     }
//     img{
//         /* display: block; */
//         /* width: 100%; */
//         /* max-height: 100%; */
//     }
//     .img{
//         &1{
//             width: 40%;
//         }
//         &2{
//             width: 60%;
//         }
//     }
//     .row{
//         max-height: 33%;
//     }


// `
const Imggrid = styled.div`
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(5, 1fr);
    grid-gap: 5px;
    /* grid-template-areas:
    "a a a b b b b b "
    "a a a a b b b b"
    "c c c c d d d d"
    "c c c c d d d d"; */


    width: 100%;
    max-width: 1010px;
    height: 1000px;

    margin: auto;
    div{
        background-color: #000;
        border-radius: 5px;
    }
    .second_grid{
        background: #fff;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 1fr;
        grid-gap: 5px;


        width: 100%;
        grid-column: 1/6;
    }
    .div{
        &1{
            grid-column: 1/3;
            grid-row: 1/3;

            background: url(${img6}) no-repeat center;
            background-size: cover;
        }
        &2{
            grid-column: 3/6;
            grid-row: 1/3;

            background: url(${img2}) no-repeat center;
            background-size: cover;

        }
        &3{
            grid-column: 1/4;
            grid-row: 3/5;

            background: url(${img1}) no-repeat center;
            background-size: cover;
        }
        &4{
            grid-column: 4/6;
            grid-row: 3/5;

            background: url(${img5}) no-repeat center;
            background-size: cover;
        }
        &5{
            background: url(${img4}) no-repeat center;
            background-size: cover;
        }
        &6{
            background: url(${img3}) no-repeat center;
            background-size: cover;
        }
        &7{
            background: url(${img6}) no-repeat center;
            background-size: cover;
        }
    }



`